<template>
  <page>
    <template #navigation>
      <div class="flex space-x-2 items-center">
        <a
          @click="$router.go(-1)"
          class="
            rounded
            inline-flex
            items-center
            justify-center
            min-w-min
            uppercase
            tracking-wide
            whitespace-nowrap
            h-8
            w-8
            text-sm
            border border-blue-900
            text-blue-700
            hover:bg-blue-700 hover:text-white
            bg-transparent
          "
          small
          ><icon face="mdiChevronLeft"
        /></a>
        <span
          class="
            text-xs text-green-700
            uppercase
            whitespace-nowrap
            hidden
            xs:block
          "
          >{{ $t('estates.view') }}</span
        >
        <router-link
          class="
            rounded
            inline-flex
            items-center
            justify-center
            min-w-min
            uppercase
            tracking-wide
            whitespace-nowrap
            h-8
            w-8
            text-sm
            border border-blue-900
            text-blue-100
            bg-blue-700
            hover:text-white hover:shadow-md
            active:shadow-none
          "
          small
          :to="estateEditURL"
          ><icon face="mdiPencil"
        /></router-link>
        <action-icon
          @click="toggleSpecialLink"
          face="mdiExportVariant"
          variant="success"
          small
        />
        <action-icon
          @click="onConfirmDelete"
          face="mdiDeleteForeverOutline"
          variant="danger"
          small
        />
      </div>
    </template>
    <h1 class="text-xl md:text-3xl">{{ estate.title }}</h1>
    <div class="flex flex-col md:flex-row mt-4">
      <div
        v-if="estate.images && estate.images.length"
        class="w-full"
        :class="{ 'md:w-1/2': !isFullScreen }"
      >
        <button
          class="w-full bg-no-repeat bg-center bg-contain ratio-16/9 rounded"
          @click="isFullScreen = !isFullScreen"
          :style="{ backgroundImage: featuredImageBackgroundUrl }"
        ></button>
        <div
          class="flex items-center flex-wrap rounded-b"
          :class="{
            'border-green-200': !featuredImage.isPlan,
            'border-red-200': featuredImage.isPlan
          }"
        >
          <chip
            size="xs"
            :variant="featuredImage.isPlan ? 'red' : 'green'"
            class="whitespace-nowrap mt-2 mr-2 uppercase"
            >{{
              $t(
                `estates.${featuredImage.isPlan ? 'isPrivate' : 'isPublished'}`
              )
            }}</chip
          >
          <chip
            size="xs"
            :variant="featuredImage.isPlan ? 'red' : 'green'"
            class="whitespace-nowrap mt-2 mr-2"
            >{{ featuredImage.original }}</chip
          >
          <chip
            size="xs"
            :variant="featuredImage.isPlan ? 'red' : 'green'"
            class="whitespace-nowrap mt-2 mr-2"
            >{{ featuredImage.mime }}</chip
          >
          <chip
            size="xs"
            :variant="featuredImage.isPlan ? 'red' : 'green'"
            class="whitespace-nowrap mt-2 mr-2"
            >{{ featuredImage.niceSize }}</chip
          >
          <chip
            v-if="featuredImageDimensions"
            size="xs"
            :variant="featuredImage.isPlan ? 'red' : 'green'"
            class="whitespace-nowrap mt-2 mr-2"
            >{{ featuredImageDimensions }}</chip
          >
          <a
            class="
              w-8
              h-8
              flex
              items-center
              justify-center
              bg-blue-300
              text-blue-900
              mt-2
              rounded-full
              relative
            "
            :download="featuredImage.original"
            target="_blank"
            :href="featuredImage.download"
            ><icon face="mdiOpenInNew"
          /></a>
        </div>
        <div class="flex flex-wrap">
          <button
            v-for="(item, idx) in estate.images"
            class="
              mt-2
              w-12
              h-12
              rounded
              mr-2
              flex
              items-center
              justify-center
              p-1
            "
            :class="{
              'ring-2 ring-blue-700': featuredImageIndex === idx,
              'bg-green-300': !item.isPlan,
              'bg-red-300': item.isPlan
            }"
            :key="`${item.isPlan ? 'plan' : 'media'}-${item._id}`"
            @click="setFeaturedImageIndex(idx)"
          >
            <img
              v-if="item.src"
              :src="item.src"
              class="max-w-full max-h-full rounded"
              @load="(event) => onImageLoad(item, event)"
            />
            <img v-else :src="fileIcon" class="w-6 squared" />
          </button>
        </div>
        <div class="flex flex-wrap">
          <div class="flex items-center mt-4">
            <div class="w-4 h-4 bg-green-300 rounded"></div>
            <div class="px-4 text-xs">{{ $t('estates.images') }}</div>
          </div>
          <div class="flex items-center mt-4">
            <div class="w-4 h-4 bg-red-300 rounded"></div>
            <div class="px-4 text-xs">{{ $t('estates.plans') }}</div>
          </div>
        </div>
      </div>
      <div
        class="w-full mt-4 md:mt-0"
        :class="{ 'md:w-1/2 md:pl-4': estate.images && estate.images.length }"
        v-if="!isFullScreen"
      >
        <card compact>
          <tag
            class="text-md select-none item mx-1 mt-2 border border-blue-400"
            v-for="item in estateTags"
            :key="item.header.id"
            :invert="item.header.inverted"
          >
            <div class="bg-blue-100">
              {{ item.header.name }}
            </div>
            <div v-if="item.display" class="bg-blue-100 font-bold">
              {{ item.display }}
            </div>
          </tag>
          <div class="mt-3" v-for="h in displayHeaders" :key="h.id">
            <div class="text-xs text-gray-400">{{ h.name }}</div>
            <p class="text-sm text-gray-700">
              {{ renderEstateValue(h) }}
            </p>
          </div>
        </card>
      </div>
    </div>
    <modal
      v-if="isConfirmingDelete"
      :title="$t('estates.deleteTitle')"
      @close="isConfirmingDelete = false"
    >
      <p>{{ $t('estates.deleteText') }}</p>
      <div class="flex justify-between mt-4">
        <action
          variant="danger"
          :disabled="isDeleting"
          :loading="isDeleting"
          @click="onDelete"
          >{{ $t('actions.yesSure') }}</action
        >
        <action
          variant="success"
          :disabled="isDeleting"
          @click="isConfirmingDelete = false"
          >{{ $t('actions.no') }}</action
        >
      </div>
    </modal>
    <modal
      v-if="specialLink"
      :title="$t('special.title')"
      @close="toggleSpecialLink"
    >
      <label class="block text-sm font-bold">
        {{ $t('special.images') }}
        {{ specialLink.images.length }} / {{ estate.images.length }}
      </label>
      <div class="flex flex-wrap px-1">
        <button
          v-for="item in estate.images"
          class="
            mt-2
            w-12
            h-12
            rounded
            mr-2
            flex
            items-center
            justify-center
            p-1
          "
          :class="{
            'ring-2 ring-blue-700': isSpecialSelected(item),
            'bg-green-300': !item.isPlan,
            'bg-red-300': item.isPlan
          }"
          :key="`${item.isPlan ? 'plan' : 'media'}-${item._id}`"
          @click="toggleSpecialSelected(item)"
        >
          <img
            v-if="item.src"
            :src="item.src"
            class="max-w-full max-h-full rounded"
          />
          <img v-else :src="fileIcon" class="w-6 squared" />
        </button>
      </div>
      <div class="mt-4">
        <checkbox v-model="specialLink.summary" i18n="special.summary" />
        <checkbox v-model="specialLink.address" i18n="special.address" />
        <checkbox v-model="specialLink.contact" i18n="special.contact" />
      </div>
      <div class="flex flex-row">
        <input
          ref="specialLinkUrl"
          type="text"
          readonly
          :value="specialLinkURL"
          class="border border-green-400 w-full block py-2 px-4 text-sm rounded"
        />
      </div>
      <div class="flex mt-4 justify-between">
        <action-icon
          @click="shareSpecialLink"
          face="mdiExportVariant"
          variant="success"
          :loading="isGenerating"
          :disabled="isGenerating"
        />
        <action
          variant="outline"
          @click="toggleSpecialLink"
          :disabled="isGenerating"
          >{{ $t('actions.close') }}</action
        >
      </div>
    </modal>
  </page>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import fileIcon from '@/assets/file-icon.png'
import { getMediaURL, getNiceSize } from '@/services'
import { getProcHeaders } from '@/services'
import priceOptions from '@/config/priceOptions'

export default {
  data() {
    return {
      featuredImageIndex: -1,
      isFullScreen: false,
      dimensions: {},
      isConfirmingDelete: false,
      specialLink: null,
      isGenerating: false,
      specialLinkURL: ''
    }
  },
  mounted() {
    if (this.loaded) {
      this.setInitialFeaturedImageIndex()
    }
  },
  watch: {
    loaded(to, from) {
      if (to && !from) {
        this.setInitialFeaturedImageIndex()
      }
    }
  },
  computed: {
    ...mapState({
      loaded: (state) => state.loaded,
      isBusy: (state) =>
        state.settings.loading ||
        state.settings.updating ||
        state.headers.loading ||
        state.headers.updating,
      settings: (state) => state.settings,
      headers: (state) => state.headers,
      estates: (state) => state.estates,
      images: (state) => state.images,
      zones: (state) => state.zones,
      contacts: (state) => state.contacts,
      types: (state) => state.types,
      isDeleting: (state) => state.estates.updating
    }),
    featuredImageBackgroundUrl() {
      return this.featuredImage.src
        ? `url(${this.featuredImage.src})`
        : `url(${this.fileIcon})`
    },
    featuredImageDimensions() {
      if (!this.featuredImage?.src) {
        return false
      }
      if (!this.dimensions?.[this.featuredImage._id]) {
        return false
      }
      const { width, height } = this.dimensions[this.featuredImage._id]
      return `${width}×${height} px`
    },
    procHeaders() {
      if (!this.loaded) {
        return []
      }
      const _settings = this.settings.items.find(
        (item) => item.name === 'estates'
      )
      const _headers = this.headers?.items.find(
        (item) => item.name === 'estates'
      )
      return getProcHeaders(_settings.items, _headers?.items)
    },
    displayHeaders() {
      const omit = ['title', 'images', 'plans']
      return this.procHeaders.filter((item) => {
        if (omit.includes(item.id)) {
          return false
        }
        if (item.tag) {
          return false
        }
        const value = this.estate[item.id]
        if (!value) {
          return false
        }
        if (Array.isArray(value) && value.length === 0) {
          return false
        }
        if (typeof value === 'object' && Object.keys(value).length === 0) {
          return false
        }
        return true
      })
    },
    taggableHeaders() {
      return this.procHeaders.filter((h) => !!h.tag)
    },
    fileIcon() {
      return fileIcon
    },
    mappedFiles() {
      if (!this.images.loaded) {
        return {}
      }
      return this.images.items.reduce(
        (all, img) => ({ ...all, [img._id]: img }),
        {}
      )
    },
    estateId() {
      return this.$route.params.id
    },
    estateEditURL() {
      return `/estates/${this.estateId}/edit`
    },
    estate() {
      if (!this.loaded) {
        return {}
      }
      const estate = this.estates.items.find(
        (item) => item._id === this.estateId
      )
      if (!estate) {
        return {}
      }

      const images =
        estate.images
          ?.map((id) => {
            const img = this.mappedFiles?.[id]
            if (!img) {
              return false
            }
            const niceSize = getNiceSize(img, 1)
            const isImage = img.mime.startsWith('image/')
            return {
              id,
              ...img,
              src: isImage ? getMediaURL(img) : '',
              download: getMediaURL(img),
              niceSize: `${niceSize.value} ${niceSize.unit}`
            }
          })
          .filter((item) => !!item) || []

      const plans =
        estate.plans
          ?.map((id) => {
            const img = this.mappedFiles?.[id]
            if (!img) {
              return false
            }
            const niceSize = getNiceSize(img, 1)
            const isImage = img.mime.startsWith('image/')
            return {
              id,
              ...img,
              src: isImage ? getMediaURL(img) : '',
              download: getMediaURL(img),
              niceSize: `${niceSize.value} ${niceSize.unit}`,
              isPlan: true
            }
          })
          .filter((item) => !!item) || []

      return {
        ...estate,
        images: [...images, ...plans]
      }
    },
    featuredImage() {
      if (!this.loaded) {
        return {}
      }

      return this.featuredImageIndex >= 0
        ? this.estate.images[this.featuredImageIndex]
        : {}
    },
    renderFunctions() {
      const renderList = (header, value) => {
        if (header.source.single) {
          const opt = header.source.options.find((item) => item.value === value)
          return this.$t(opt.label)
        } else {
          return header.source.options
            .filter((item) => value.includes(item.value))
            .map((item) => this.$t(item.label))
            .join(', ')
        }
      }
      const renderLocation = (header, value) => {
        if (value?.lat && value?.lng) {
          return `${value.lat}×${value.lng}`
        }
        return '-'
      }
      const renderCollection = (header, value) => {
        const val = header.source.single ? [value] : value
        const coll = this.getCollectionById(header.id)
        const collHeaders = this.headers.items.find(
          (item) => item.id === header.source.name
        )?.items

        const mainKey =
          collHeaders?.find((item) => item.main)?.id ||
          collHeaders?.[0].id ||
          'name'

        return coll
          .filter((item) => val.includes(item._id))
          .map((item) => item[mainKey])
          .join(', ')
      }

      const renderPrice = (header, value) => priceOptions.format(value)

      const renderMoment = (header, value) =>
        moment.utc(value).local().format('LLLL')

      return {
        L: renderList,
        G: renderLocation,
        C: renderCollection,
        P: renderPrice,
        R: renderPrice,
        U: renderMoment
      }
    },
    estateTags() {
      if (!this.estate) {
        return []
      }
      const tagsOrder = this.estate._tagOrder || {}

      return this.taggableHeaders
        .map((header) => ({
          header,
          value: this.estate?.[header.id],
          display: this.getTaggableDisplay({
            header,
            value: this.estate?.[header.id]
          })
        }))
        .sort((a, b) => {
          const idxA = tagsOrder[a.header.id]
          const idxB = tagsOrder[b.header.id]
          return idxB === undefined ? 1 : idxA === undefined ? -1 : idxA - idxB
        })
        .filter((item) =>
          item.header.type === 'B' && item.value ? false : this.notEmpty(item)
        )
    }
  },
  methods: {
    ...mapActions(['estates/destroy']),
    setInitialFeaturedImageIndex() {
      const firstImage = this.estate.images.findIndex((img) => !!img.src)
      this.featuredImageIndex = firstImage
    },
    setFeaturedImageIndex(idx) {
      this.featuredImageIndex = idx
    },
    onImageLoad(estateImage, event) {
      const width = event.target.naturalWidth
      const height = event.target.naturalHeight
      this.$set(this.dimensions, estateImage._id, { width, height })
    },
    getCollectionById(id) {
      switch (id) {
        case 'zoneId':
          return this.zones.items
        case 'contactId':
          return this.contacts.items
        case 'typeId':
          return this.types.items
        default:
          return []
      }
    },
    renderEstateValue(header) {
      if (this.renderFunctions[header.type]) {
        return this.renderFunctions[header.type](header, this.estate[header.id])
      }
      return this.estate[header.id]
    },
    notEmpty({ value }) {
      return value !== null && value !== undefined && value !== ''
    },
    getTaggableDisplay({ header, value }) {
      switch (header.type) {
        case 'B':
          return value ? null : this.$t('bool.no')
        default:
          return value
      }
    },
    onConfirmDelete() {
      this.isConfirmingDelete = true
    },
    async onDelete() {
      await this['estates/destroy']({ _id: this.estateId })
      this.$router.push('/estates')
    },
    toggleSpecialLink() {
      if (this.specialLink) {
        this.specialLink = null
        return
      }
      this.specialLinkURL = ''
      this.isGenerating = false
      this.specialLink = {
        estateId: this.estate._id,
        summary: false,
        contact: false,
        address: false,
        images: this.estate.images
          .filter((item) => !item.isPlan)
          .map((item) => item._id)
      }
    },
    isSpecialSelected(img) {
      if (!this.specialLink) {
        return false
      }
      return this.specialLink.images.includes(img._id)
    },
    toggleSpecialSelected(img) {
      if (!this.specialLink) {
        return false
      }
      const idx = this.specialLink.images.indexOf(img._id)
      if (idx >= 0) {
        this.specialLink.images.splice(idx, 1)
      } else {
        this.specialLink.images.push(img._id)
      }
    },
    async generateLink() {
      if (!this.specialLink) {
        return
      }
      try {
        this.isGenerating = true
        this.specialLinkURL = ''
        const { data } = await this.$http.post(
          '/collection/links',
          this.specialLink
        )
        this.specialLinkURL = `${process.env.VUE_APP_WEBSITE_URL}/special/${data.data._id}-${data.data.rnd}`
      } catch (err) {
        console.error('Error while creating link', err)
      } finally {
        this.isGenerating = false
      }
    },
    async shareSpecialLink() {
      await this.generateLink()
      if (!this.specialLinkURL) {
        return
      }
      try {
        const canShare = await navigator.canShare()
        if (canShare) {
          const shareData = {
            title: this['estates/destroy'].estate.title,
            url: this.specialLinkURL
          }
          await navigator.share(shareData)
          return
        }
      } catch (err) {
        console.error('Sharing is not supported by navigator')
      }
      this.$nextTick(() => {
        this.$refs.specialLinkUrl.focus()
        this.$refs.specialLinkUrl.select()
        document.execCommand('copy')
      })
    }
  }
}
</script>
